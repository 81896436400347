<template>
  <div id="purchaseRecord">
    <div id="writeoff">
      <pagination2
        :option="post"
        url="/LiveGoods/orderList"
        ref="childDialogBox"
        class="childDialogBox"
      >
        <template #other>
          <div class="top">
            <el-row>
              <el-col :span="8">代金券名称：{{ ruleForm.name }}</el-col>
              <el-col :span="8">
                当前售价：
                <span v-if="ruleForm.price_type == 1">
                  <span v-if="ruleForm.price">￥{{ ruleForm.price }}</span>
                  <span v-else>￥0</span>
                </span>
                <span v-else>-</span>
              </el-col>
              <el-col :span="8">已售(张)：{{ ruleForm.sale_num }}</el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                支付方式：{{
                  ruleForm.price_type == 1 ? '直播间支付' : '跳转支付'
                }}
              </el-col>
              <!-- <el-col :span="8">
                状态：{{ ruleForm.state == 1 ? '已启用' : '未启用' }}
              </el-col> -->
            </el-row>
          </div>
          <div class="main-contain">
            <el-tabs v-model="post.is_check">
              <el-tab-pane label="待核销" name="2"></el-tab-pane>
              <el-tab-pane label="已核销" name="1"></el-tab-pane>
            </el-tabs>
          </div>
          <div class="input">
            <el-input
              size="medium"
              style="width: 338px"
              :placeholder="
                post.search_type == 1 ? '输入核销码搜索 ' : '输入学员昵称搜索 '
              "
              v-model="post.search"
              class="input-with-select"
            >
              <el-select
                style="width: 120px"
                v-model="post.search_type"
                slot="prepend"
              >
                <el-option label="核销码" value="1"></el-option>
                <el-option label="学员" value="2"></el-option>
              </el-select>
              <!-- 搜索 -->
            </el-input>
          </div>
        </template>

        <template v-slot:default="{ tableData: { list } }">
          <el-table
            :key="post.is_check"
            @selection-change="selectionChange"
            class="table-list"
            :header-cell-style="{
              background: 'rgba(245,245,245,1)',
              color: '#333333',
            }"
            :data="list"
          >
            <el-table-column
              type="selection"
              v-if="post.is_check == 2"
              width="45px"
            ></el-table-column>

            <el-table-column label="学员名称">
              <template slot-scope="{ row }">
                <div class="good_contain">
                  <div class="pic">
                    <img :src="row.uphoto" alt="" />
                  </div>
                  <div class="name_price">
                    <div class="name">{{ row.uname }}</div>
                    <!-- <div class="price">
                      <span v-if="row.price">￥{{ row.price }}</span>
                      <span class="corss_price" v-if="row.cost_price">
                        ￥{{ row.cost_price }}
                      </span>
                    </div> -->
                  </div>
                </div>
              </template>
            </el-table-column>

            <el-table-column label="购买时间">
              <template slot-scope="{ row }">
                {{ row.order_time | formatTimeStamp('yyyy-MM-dd hh:mm:ss') }}
              </template>
            </el-table-column>

            <el-table-column>
              <div slot="header">
                出售价
                <helpIcon
                  style="margin-left: 6px"
                  content="出售价为学员当时购买的价格，不会根据最近编辑的售价变化。可能会出现售价不统一的情况"
                ></helpIcon>
              </div>
              <template slot-scope="{ row }">
                <div class="redcolor">￥{{ row.price }}</div>
              </template>
            </el-table-column>

            <el-table-column label="代金额">
              <template slot-scope="{ row }">
                <div class="redcolor">￥{{ row.cost_price }}</div>
              </template>
            </el-table-column>

            <el-table-column label="核销码" prop="number"></el-table-column>

            <el-table-column label="状态" width="100px">
              <template slot-scope="{ row }">
                <span v-if="row.is_check == 2" style="color: #3d80ef">
                  待核销
                </span>
                <span v-else style="color: #ff3535">已核销</span>
              </template>
            </el-table-column>

            <el-table-column label="核销时间" v-if="post.is_check == 1">
              <template slot-scope="{ row }">
                <span v-if="row.check_time > 0">
                  {{ row.check_time | formatTimeStamp('yyyy-MM-dd hh:mm:ss') }}
                </span>
                <span v-else>-</span>
              </template>
            </el-table-column>

            <el-table-column label="备注" v-if="post.is_check == 1">
              <template slot-scope="{ row }">
                <span v-if="row.remarks">{{ row.remarks }}</span>
                <span v-else>-</span>
              </template>
            </el-table-column>

            <el-table-column label="操作" width="180px">
              <template slot-scope="{ row }">
                <div class="btns">
                  <el-button
                    :disabled="row.price_type == 2"
                    type="text"
                    @click="
                      $router.push({
                        path:
                          '/capitalOrder/cashcouponDetail/' +
                          row.live_goods_order_id,
                      })
                    "
                  >
                    查看订单
                  </el-button>
                  <span class="sp_dir">|</span>
                  <el-button
                    v-if="row.is_check == 2"
                    type="text"
                    @click="writeOff(row.live_goods_order_id, undefined)"
                  >
                    核销
                  </el-button>
                  <el-button
                    v-else
                    type="text"
                    @click="writeOff(row.live_goods_order_id, row.remarks)"
                  >
                    备注
                  </el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <template #footer v-if="post.is_check == 2">
          <div class="footer flex-center">
            已选择{{ selectionVal.length }} 条
            <el-button
              :disabled="selectionVal.length == 0"
              size="medium"
              class="ml20"
              @click="writeOff(undefined)"
              style="width: 130px"
            >
              批量核销
            </el-button>
          </div>
        </template>
      </pagination2>
    </div>
    <el-dialog
      @close="cancel"
      :close-on-click-modal="true"
      class="dialogVisible"
      :visible.sync="dialogVisible"
      width="422px"
    >
      <div slot="title">
        {{ post.is_check == 2 ? '核销' : '备注' }}
      </div>
      <span>
        <div v-if="post.is_check == 2" class="titledialogVisible">
          是否确定核销该代金券？建议您核对学员信息后确认！
        </div>
        <el-input
          v-model="remarks"
          placeholder="备注的内容学员不会看到，不超过20个字符，选填"
          maxlength="20"
        ></el-input>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="dialogVisible = false"
          size="medium"
          style="width: 74px"
        >
          取 消
        </el-button>
        <el-button
          type="primary"
          @click="submit"
          style="width: 74px"
          size="medium"
        >
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import helpIcon from '@/components/helpIcon'
export default {
  name: 'purchaseRecord',

  components: {
    helpIcon,
  },

  data() {
    return {
      selectionVal: [],
      remarks: '',
      dialogVisible: false,
      ruleForm: {},
      post: {
        live_goods_id: this.$route.query.id,
        search_type: '1',
        is_check: '2',
        search: '',
        status: 2,
      },
    }
  },
  created() {
    this.setVoucher()
  },
  methods: {
    selectionChange(val) {
      this.selectionVal = val
      console.log
    },

    writeOff(id, remarks) {
      this.dialogVisible = true
      if (id) {
        this.live_goods_order_id = [id]
        if (remarks) {
          this.remarks = remarks
        }
      } else {
        this.live_goods_order_id = this.selectionVal.map(
          (item) => item.live_goods_order_id
        )
      }
    },

    cancel() {
      this.remarks = ''
    },

    submit() {
      this.$http({
        url: `/LiveGoods/check`,
        data: {
          live_goods_order_id: this.live_goods_order_id,
          remarks: this.remarks,
          type: this.post.is_check == '1' ? 1 : undefined,
        },
        callback: () => {
          this.$refs.childDialogBox.reset()
          this.dialogVisible = false
          this.remarks = ''
          this.$root.prompt({
            msg: '操作成功',
            type: 'success',
          })
        },
      })
    },
    setVoucher() {
      this.$http({
        url: `/LiveGoods/setVoucher?live_goods_id=${this.$route.query.id}`,
        callback: ({ data }) => {
          this.ruleForm = data
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
#writeoff {
  .table-list {
    padding: 20px 20px 0;
  }
  .input {
    padding-right: 20px;
    background-color: #fff;
    display: flex;
    justify-content: flex-end;
    .el-input-group__prepend {
      width: 55px;
    }
  }
  .btns {
    display: flex;
    align-items: center;
    .sp_dir {
      color: #1b9d97;
      margin: 0 10px;
    }
  }
  .childDialogBox {
    .good_contain {
      display: flex;

      .pic {
        width: 30px;
        height: 30px;
        min-width: 30px;
        margin-right: 10px;
        img {
          object-position: 50%;
          object-fit: contain;
          width: 100%;
          height: 100%;
        }
      }
      .name_price {
        flex: 1;
        overflow: hidden;
        .name {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 14px;
          color: #333333;
        }
        .price {
          margin-top: 20px;
          font-size: 14px;
          color: #ff3535;
          .corss_price {
            margin-left: 10px;
            text-decoration: line-through;
            color: #999999;
          }
        }
      }
    }
    .btns {
      display: flex;
      align-items: center;
      .sp_dir {
        color: #1b9d97;
        margin: 0 10px;
      }
    }
    .footer {
      margin-top: 20px;
      font-size: 14px;
      color: #333333;
      padding-bottom: 20px;
    }
  }
}
::v-deep .input-with-select {
  .el-input__inner {
    color: #333333 !important;
  }
  .el-input-group__prepend {
    background-color: #fff;
  }
}
::v-deep .footer {
  background-color: #fff;
  margin-top: 0 !important;
  padding-top: 20px;
  padding-left: 20px;
}
::v-deep .dialogVisible {
  .titledialogVisible {
    font-size: 14px;
    color: #666666;
    line-height: 22px;
    margin-bottom: 12px;
  }
}
::v-deep .pagination {
  background-color: #fff;
}

#purchaseRecord {
  .top {
    background-color: #fff;
    padding: 20px;
    margin-bottom: 20px;
    .el-row {
      font-size: 12px;
      color: #333333;
      &:first-child {
        margin-bottom: 23px;
      }
    }
  }
  .main-contain {
    background-color: #fff;
    ::v-deep .el-tabs__nav-scroll {
      padding: 0 20px;
    }
    ::v-deep .el-tabs__nav-wrap::after {
      height: 1px !important;
    }
  }
}
</style>